<template>
  <div>
    <b-row>
      <b-col sm="4">
        <RWCard>
          <!-- title cart -->
          <template v-slot:title>
            <h3 class="kt-portlet__head-title">
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M12 11a4 4 0 110-8 4 4 0 010 8z" fill="#000" fill-rule="nonzero" opacity=".3"/><path d="M3 20.2c.388-4.773 4.262-7.2 8.983-7.2 4.788 0 8.722 2.293 9.015 7.2.012.195 0 .8-.751.8H3.727c-.25 0-.747-.54-.726-.8z" fill="#000" fill-rule="nonzero"/></g></svg>
              Profile
            </h3>
          </template>

          <!-- body -->
          <template v-slot:body>
            <div class="text-center">
              <h4>Status Akun :</h4>
              <b-alert show class="status" variant="warning">Pending</b-alert>
              <b-alert show class="status" variant="success">Active</b-alert>
              <b-alert show class="status" variant="danger">Suspended</b-alert>
            </div>
          </template>
        </RWCard>
      </b-col>

      <b-col sm="8">
        <RWCard>
          <!-- title cart -->
          <template v-slot:title>
            <h3 class="kt-portlet__head-title">
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M4 4l7.631-1.43a2 2 0 01.738 0L20 4v9.283a8.51 8.51 0 01-4 7.217l-3.47 2.169a1 1 0 01-1.06 0L8 20.5a8.51 8.51 0 01-4-7.217V4z" fill="#000" opacity=".3"/><path d="M14.5 11a1 1 0 011 1v3a1 1 0 01-1 1h-5a1 1 0 01-1-1v-3a1 1 0 011-1v-.5a2.5 2.5 0 115 0v.5zM12 9a1.5 1.5 0 00-1.5 1.5v.5h3v-.5A1.5 1.5 0 0012 9z" fill="#000"/></g></svg>
              Data Rekening Bank
            </h3>
          </template>

          <!-- body -->
          <template v-slot:body>
            <b-alert variant="info" show>
              Permintaan perubahan data rekening bank Anda sedang dalam proses
              verifikasi oleh Rumahweb
            </b-alert>

            <b-form-group
              id="fieldset-horizontal"
              label-cols-sm="3"
              label="Nama Bank"
              label-for="input-horizontal"
            >
              <b-form-input id="input-horizontal"></b-form-input>
            </b-form-group>

            <b-form-group
              id="fieldset-horizontal"
              label-cols-sm="3"
              label="Pemilik Rekening"
              label-for="input-horizontal"
            >
              <b-form-input id="input-horizontal"></b-form-input>
            </b-form-group>
            <b-form-group
              id="fieldset-horizontal"
              label-cols-sm="3"
              label="Nomor Rekening"
              label-for="input-horizontal"
            >
              <b-form-input id="input-horizontal"></b-form-input>
            </b-form-group>
            <b-form-group
              id="fieldset-horizontal"
              label-cols-sm="3"
              label="Kantor Cabang"
              label-for="input-horizontal"
            >
              <b-form-input id="input-horizontal"></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols-sm="3"
              label="Scan KTP"
              label-for="input-horizontal"
              description="Tipe file .jpg atau .png dan maksimal ukuran 500KB"
            >
              <b-form-file
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
            </b-form-group>
            <b-form-group>
              <b-row>
                <b-col sm="3" offset-sm="3">
                  <b-button variant="outline-success" block>Simpan</b-button>
                </b-col>
              </b-row>
            </b-form-group>
          </template>
        </RWCard>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RWCard from "@/views/partials/content/Portlet.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
export default {
  components: {
    RWCard
  },
  data() {
    return {
      file: ""
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Afiliasi" },
      { title: "Profile" }
    ]);
  }
};
</script>

<style scoped>
.alert.status {
  font-size: 1.5em;
  text-align: center;
}
</style>
